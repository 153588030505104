<template>
    <div class="contents">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    List Product Link Rotator
                                </h4>
                            </div>
                        </div>
                        <div class="action-btn">
                            <router-link to="/link-rotator/create" class="btn px-15 btn-primary text-white"
                            style="display: inline-flex; margin: 5px">
                                <i class="fas fa-plus fs-16"></i>
                                Tambah Link Rotator
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="linkRotatorQuota == -1" class="alert alert-success" role="alert">
                <div class="alert-content">
                    <p>Batas link rotator maksimal anda adalah <b>Unlimited</b> link rotator</p>
                </div>
            </div>
            <div v-else-if="linkRotatorQuota == 0" class="alert alert-danger" role="alert">
                <div class="alert-content">
                    <p>Batas link rotator maksimal anda adalah <b>{{ linkRotatorQuota }}</b> link rotator</p>
                </div>
            </div>
            <div v-else class="alert alert-primary" role="alert">
                <div class="alert-content">
                    <p>Batas link rotator maksimal anda adalah <b>{{ linkRotatorQuota }}</b> link rotator</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <LinkRotatorTable  />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LinkRotatorTable from "@/components/tables/LinkRotator.vue";

    export default {
        components: {
            LinkRotatorTable,
        },
        data() {
            return {
                linkRotatorQuota: 0
            };
        },
        mounted() {
            this.linkRotatorQuota = this.$store.state.auth.package.max_link_rotator
        },
        methods: {
        },
    };
</script>