<template>
    <div>
        <div class="contents">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Tambah Product Link Rotator</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <form v-on:submit.prevent="saveProductLinkRotator" enctype="multipart/form-data">
                                <label for="">Nama Product Link</label>
                                <input type="text" class="form-control" v-model="form.name" required>
                                <br>
                                <label for="">Short URL</label>
                                <div class="input-group mb-2 mr-sm-2">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">https://links.starsender.online/</div>
                                    </div>
                                    <input type="text" v-model="form.short_url" id="short-url" class="form-control">
                                    <button class="btn btn-dark" type="button" v-on:click="checkURL()">Cek</button>
                                </div>
                                <div v-if="urlCheck == '0'">
                                    <b class="text-danger" style="font-size:12px;">URL tidak dapat digunakan karena sudah terpakai</b>
                                </div>
                                <div v-else-if="urlCheck == '1'">
                                    <b class="text-success" style="font-size:12px;">URL dapat digunakan</b>
                                </div>
                                <br>
                                <label for="">Tipe</label>
                                <select name="type" class="form-control" v-model="form.type" required>
                                    <option value="" selected disabled>== PILIH TIPE ==</option>

                                    <option value="direct">Direct</option>
                                    <option value="lander">Lander</option>
                                </select>
                                <div v-if="form.type == 'lander'">
                                    <br>
                                    <div class="card thick-border">
                                        <div class="card-body">
                                            <label for="">Tracking Option</label>
                                            <select v-model="form.tracking_option" class="form-control"
                                                id="select-tracking">
                                                <option value="" selected disabled>== PILIH TRACKING OPTION ==</option>

                                                <option value="fb_pixel">Facebook Pixel</option>
                                                <option value="google_tag">Google Tag Manager</option>
                                            </select>
                                            <div v-if="form.tracking_option == 'fb_pixel'">
                                                <br>
                                                <label for="">Pixel ID</label>
                                                <input type="text" class="form-control" v-model="form.tracking_tag">
                                            </div>
                                            <div v-if="form.tracking_option == 'google_tag'">
                                                <br>
                                                <label for="">Google Tag ID</label>
                                                <input type="text" class="form-control" v-model="form.tracking_tag">
                                            </div>
                                            <br>
                                            <label for="">Status Form Lander</label>
                                            <br>
                                            <label class="switch">
                                                <input type="checkbox" v-model="form.form_status">
                                                <span class="slider round"></span>
                                            </label>
                                            <div v-if="form.form_status">
                                                <br>
                                                <label for="">Header Form</label>
                                                <vue-editor v-model="form.header" />
                                                <br>
                                                        <button type="button" v-on:click="addFormData" class="btn btn-success">Tambah Form Data</button>
                                                        <div v-for="(fdata, idx_fdata) in this.form.form_data" v-bind:key="fdata.id">
                                                            <br>
                                                            <div class="card thick-border">
                                                                <div class="card-body">
                                                            <div class='row'>
                                                                <div class='col-md-6'>
                                                                    <label id='label'>Label</label>
                                                                    <input type='text' class='form-control' v-model='fdata.label' required>
                                                                </div>
                                                                <div class='col-md-3'>
                                                                    <label id='bobot'>Tipe Input</label>
                                                                    <select v-model='fdata.type' class="form-control">
                                                                        <option value="text">Text</option>
                                                                        <option value="number">Number</option>
                                                                        <option value="textarea">Text Area</option>
                                                                        <option value="dropdown">Dropdown</option>
                                                                        <option value="radio">Radio</option>
                                                                    </select>

                                                                </div>
                                                                <div class='col-md-3'>
                                                                    <button style='margin-top: 32px;width: 100%;' type='button' v-on:click="removeFormData(idx_fdata)" class='btn btn-danger'>
                                                                        Hapus
                                                                    </button>
                                                                </div>
                                                                <div class='col-md-12' v-if="fdata.type == 'dropdown' || fdata.type == 'radio'">
                                                                    <br>
                                                                    <label for="">Value Dropdown / Radio (Pisahkan value dengan |)</label>
                                                                    <input type='text' class='form-control' v-model='fdata.value' required>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <br>
                                <label for="">WA Target</label>
                                <select v-model="form.target" class="form-control" required>
                                    <option value="" selected disabled>== PILIH TARGET ==</option>
                                    <option value="single">Single</option>
                                    <option value="rotator" selected>Rotator</option>
                                </select>
                                <div v-if="form.target == 'single'">
                                    <br>
                                    <label for="">Nomor Whatsapp</label>
                                    <input type="number" class="form-control" v-model="form.number_single">
                                </div>
                                <div v-else-if="form.target == 'rotator'">
                                    <br>
                                    <a v-on:click="addNumber" class="btn btn-success text-white"
                                        style="display:inline-flex">Tambah Nomor Whatsapp</a>
                                    <div class="form-group mb-20">
                                        <div v-for="(num, idx_num) in this.form.number_array" v-bind:key="num.id">
                                            <br>
                                            <div class='row'>
                                                <div class='col-md-6'>
                                                    <label id='label'>Nomor Whatsapp</label>
                                                    <input type='number' class='form-control' v-model='num.number' required>
                                                </div>
                                                <div class='col-md-3'>
                                                    <label id='bobot'>Bobot</label>
                                                    <input type='number' class='form-control' v-model='num.bobot' required>
                                                </div>
                                                <div class='col-md-3'>
                                                    <button style='margin-top: 32px;width: 100%' type='button' v-on:click="removeNumber(idx_num)" class='btn btn-danger'>
                                                        Hapus
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="number_list"></div>
                                    </div>
                                    <i><b>
                                            Bobot bukan lah persentase melainkan pola, contoh
                                            <br> Nomor A -> Bobot 3
                                            <br> Nomor B -> Bobot 2
                                            <br> Artinya Nomor A akan menerima 3 pesan, kemudian setelah 3 pesan secara
                                            berurutan akan merotasi ke Nomor B, dst
                                        </b></i>
                                    <input type="hidden" value="0" id="total_number">
                                </div>
                                <br>
                                <br>
                                <label for="">Message</label>
                                <CustomTextArea v-model="form.text"></CustomTextArea>
                                <br>
                                <div v-if="form.type == 'lander'">
                                    <i>Variabel tersedia {{ variable }}</i>
                                    <br>
                                    <i>Kosongan field message jika ingin menggunakan message default</i>
                                    <br>
                                    <br>
                                </div>
                                <button type="submit" class="btn btn-success btn-block">Simpan</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { VueEditor } from "vue2-editor";
    import CustomTextArea from "@/components/module/CustomTextarea.vue";

    export default {
        components: {
            CustomTextArea,
            VueEditor
        },
        data() {
            return {
                form: {
                    name: "",
                    short_url: "",
                    type: "",
                    target: "rotator",
                    text: "",
                    tracking_option: "",
                    tracking_tag: "",
                    number_single: "",
                    number_array: [],
                    number_array_index: 0,
                    form_status: false,
                    header: "",
                    form_data: [],
                    form_data_index: 0,
                },
                urlCheck: null,
                id: '',
                outgoing: {},
                device: {},
                history: [],
                variable: ""
            };
        },
        watch: {
            form : {
                handler(val){
                    this.variable = ""

                    for (let i = 0; i < val.form_data.length; i++) {
                        const element = val.form_data[i];
                        this.variable += "[" + element.label + "]"
                        if(val.form_data[i+1]){
                            this.variable += ", "
                        }
                    }
                },
                deep: true
            },
        },
        mounted() {
        },
        methods: {
            async checkURL() {
                try {
                    let response = await this.$store.dispatch('linkrotator_check_short_url', {"short_url": this.form.short_url})
                    if(response.data.success){
                        this.urlCheck = '1'
                    }else{
                        this.urlCheck = '0'
                    }
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            addNumber() {
                this.form.number_array.push({
                    id: "new-" + (this.form.number_array_index + 1),
                    bobot: "",
                    number: ""
                })

                this.form.number_array_index += 1

                this.$toast.success("Tambah Number Sukses");
            },
            removeNumber(idx) {
                console.log(idx)
                this.form.number_array.splice(idx, 1)
                console.log(this.form.number_array)

                this.form.number_array_index -= 1

                this.$toast.success("Hapus Number Sukses");
            },
            addFormData() {
                this.form.form_data.push({
                    //id: "new-" + (this.form.form_data_index + 1),
                    label: "",
                    type: "text",
                    value: "",
                })

                this.form.form_data_index += 1

                this.$toast.success("Tambah Form Data Sukses");
            },
            removeFormData(idx) {
                this.form.form_data.splice(idx, 1)
                this.form.form_data_index -= 1

                this.$toast.success("Hapus Form Data Sukses");
            },
            async saveProductLinkRotator() {
                try {
                    let response = await this.$store.dispatch('linkrotator_create', this.form)
                    this.$toast.success(response.data.message);
                    this.$router.push("/link-rotator")
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
        },
    };
</script>


<style scoped>
    .thick-border {
        border: 1.5px solid #6c6c6c !important;
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 17px;
    }
    
    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    
    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    .slider:before {
      position: absolute;
      content: "";
      height: 13px;
      width: 13px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    input:checked + .slider {
      background-color: #2196F3;
    }
    
    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }
    
    input:checked + .slider:before {
      -webkit-transform: translateX(13px);
      -ms-transform: translateX(13px);
      transform: translateX(13px);
    }
    
    /* Rounded sliders */
    .slider.round {
      border-radius: 17px;
    }
    
    .slider.round:before {
      border-radius: 50%;
    }
    
    </style>