<template>
    <div>
      <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
        :isLoading.sync="table.isLoading" :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
            perPageDropdown: [10, 25, 50, 100],
          }" :columns="table.columns" :rows="table.rows">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'action'">
            <router-link :to="'/link-rotator/detail?id=' + props.row.id">
              <a class="btn btn-primary text-white margin5">Detail</a>
            </router-link>
            <a v-on:click="deleteContact(props.row.id)" class="btn btn-danger text-white margin5">
              Hapus
            </a>
          </span>
          <span v-else-if="props.column.field == 'data'">
            <div v-html="parseData(props.row.data)"></div>
          </span>
          <span v-else-if="props.column.field == 'user_agent'">
            {{ parseUserAgent(props.row.user_agent) }}
          </span>
          <span v-else-if="props.column.field == 'created_at'">
            {{ convertDateToLocal(props.row.created_at) }}
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
    import UAParser from 'ua-parser-js'
    import moment from 'moment'

    export default {
      data() {
        return {
          id: null,
          table: {
            total_records: 100,
            isLoading: true,
            columns: [{
                label: "Nomor WA Tujuan",
                field: "whatsapp.number",
                filterOptions: {
                  enabled: false,
                },
                sortable: false,
                width: "20%",
              },
              {
                label: "Data",
                field: "data",
                filterOptions: {
                  enabled: true,
                },
                sortable: false,
                width: "30%",
              },
              {
                label: "User Agent",
                field: "user_agent",
                filterOptions: {
                  enabled: false,
                },
                sortable: false,
                width: "30%",
              },
              {
                label: "Time Stamp",
                field: "created_at",
                sortable: false,
                width: "20%",
              },
            ],
            rows: [],
            serverParams: {
              columnFilters: {},
              sort: [{
                field: "",
                type: "",
              }, ],
              page: 1,
              limit: 10,
            },
          },
        };
      },
      mounted() {
        this.fn = () => {
          this.loadItems();
        }
        this.$root.$on("refresh_table", this.fn);
        this.id = this.$route.query.id
        this.loadItems();
      },
      beforeDestroy() {
        this.$root.$off("refresh_table", this.fn);
      },
      methods: {
        async deleteContact(id) {
          try {
            let response = await this.$store.dispatch("chat_widget_delete", {
              id: id
            });
            this.loadItems();
            this.$toast.success(response.data.message);
          } catch (err) {
            this.$toast.error("Server error");
          }
        },
        updateParams(newProps) {
          this.table.serverParams = Object.assign({},
            this.table.serverParams,
            newProps
          );
        },
        onPageChange(params) {
          this.updateParams({
            page: params.currentPage
          });
          this.loadItems();
        },
        onPerPageChange(params) {
          this.updateParams({
            limit: params.currentPerPage
          });
          this.loadItems();
        },
        onSortChange(params) {
          this.updateParams({
            sort: [{
              type: params.sortType,
              field: this.table.columns[params.columnIndex].field,
            }, ],
          });
          this.loadItems();
        },
        onColumnFilter(params) {
          this.updateParams(params);
          this.loadItems();
        },
        async loadItems() {
          this.table.isLoading = true
          try {
            let response = await this.getFromServer({id: this.id, ...this.table.serverParams});
            this.table.total_records = response.data.data.total_records;
            this.table.rows = response.data.data.history;
          } catch (err) {
            this.$toast.error("Too many request");
          }
          this.table.isLoading = false
        },
        async getFromServer(param) {
          return await this.$store.dispatch("linkrotator_history_list", param);
        },
        parseUserAgent(ua){
          if(!ua) return ""

          let userAgent = new UAParser(ua)

          return userAgent.getBrowser().name + " (" + userAgent.getBrowser().version + ") | " + userAgent.getOS().name
        },
        parseData(data){
          if(!data) return ""
          let decode = JSON.parse(data)
          let text = ''

          for (let i = 0; i < decode.length; i++) {
            const element = decode[i];
            text += "<b>" + element.label + " : </b>"
            text += element.value
            text += "<br>"
          }
          return text
        },
        convertDateToLocal(date){
          return moment.utc(date).local().format("YYYY-MM-DD HH:mm:ss")
        },
      },
    };
  </script>
  
  <style scoped>
    .margin5 {
      margin: 5px;
    }
  </style>